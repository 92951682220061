.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.dropdown {
  position: relative;
}

.select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
  color: white;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  width: 200px;
}

.caret {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #fff;
  transition: transform 0.3s;
}

.caret-rotate {
  transform: rotate(180deg);
}

.menu-genre,
.menu-country,
.menu-type {
  list-style: none;
  padding: 0.5em;
  background: #333;
  color: white;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 15px;
  max-height: 200px;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 10;
}

.menu-genre-open,
.menu-country-open,
.menu-type-open {
  opacity: 1;
  visibility: visible;
}

.menu-genre li,
.menu-country li,
.menu-type li {
  padding: 0.7em 0.5em;
  cursor: pointer;
  border-radius: 15px;
}

.menu-genre li:hover,
.menu-country li:hover,
.menu-type li:hover {
  background-color: #23242a;
}
