.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  font-family: Arial, sans-serif;
  height: 100vh;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 400px;
}

.login-form h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 2rem;
}

.form-input {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.form-input input {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  background: transparent;
  color: #fff;
  font-size: 1rem;
  outline: none;
}

.form-input input:focus {
  border-bottom: 1px solid #fff;
}

.form-input label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #ccc;
  font-size: 1rem;
  transition: all 0.3s ease;
  pointer-events: none;
}

.form-input input:focus + label,
.form-input input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 0.875rem;
  color: #fff;
}

.alert {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 0.875rem;
  width: 100%;
  text-align: center;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #4b3c3c;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.login-form button:hover {
  background-color: #5a4a4a;
  transform: scale(1.05);
}

.login-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

.login-form p {
  color: #aaa;
  cursor: pointer;
  margin-top: 10px;
  font-size: 0.875rem;
}

.login-form p:hover {
  color: #fff;
}
