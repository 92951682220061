.main-content {
  flex: 1;
  background-color: #141414;
  color: white;
  padding: 15px 30px 15px 30px;
  margin-left: 50px;
}

.banner {
  margin: 20px 0;
  text-align: left;
}

.banner h1 {
  font-size: 48px;
}

.banner p {
  font-size: 18px;
}

.shows-list {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 5px 5px 5px;
  gap: 25px;
}

.show-card {
  background-color: #333;
  padding: 10px;
  border-radius: 10px;
  width: 200px;
  text-align: center;
  position: relative;
}

.card-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.show-card img {
  width: 100%;
  border-radius: 10px;
}

.show-card h3 {
  font-size: 16px;
  margin: 10px 0;
}

.show-card p {
  font-size: 12px;
  color: #bbb;
}
