.navbar {
  width: 100%;
  height: 50px;
  background: rgb(24, 24, 24);
  position: sticky;
  top: 0;
  z-index: 3;
}

.navbar-container {
  display: flex;
  align-items: center;
  padding: 0 50px;
  height: 100%;
  color: white;
}

.header-links {
  display: flex;
}

.header_login {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}
