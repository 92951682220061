.search-bar-container {
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.search-bar-input {
  width: 100%;
  padding: 8px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  max-height: 50px;
}

.navbar-movie-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.search-movie-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.movie-search {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.navbar-movie-list a{
  text-decoration: none;
}

.movie-search.no-border {
  border-bottom: none;
}

.movie-search-img {
  width: 50px;
  height: 70px;
  margin-right: 15px;
  border-radius: 4px;
}

.movie-search-info {
  flex: 1;
}

.movie-search-info h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.movie-search-info p {
  margin: 5px 0;
  color: #666;
}

.movie-search-title {
  color: #000;
  font-weight: bold;
}
