* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background-color: #141414;
  color: white;
}

.container {
  display: flex;
  height: auto;
  width: auto;
  min-height: calc(100vh - 50px);
}
