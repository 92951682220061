.container {
  max-width: 1400px;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.poster {
  width: 25%;
  padding-right: 20px;
  flex-shrink: 0;
}

.poster img {
  width: 320px;
  border-radius: 8px;
  height: 480;
}

.content {
  width: 75%;
  padding-left: 20px;
}

h1 {
  font-size: 36px;
  margin-bottom: 10px;
  color: #333;
}

h2 {
  font-size: 28px;
  margin-top: 25px;
  margin-bottom: 15px;
  color: #333;
}

p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.info-detail {
  margin-top: 25px;
}

.info-detail table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  color: #333;
}

.info-detail td {
  padding: 10px 0;
  color: #333;
}

.number_money {
  font-weight: bold;
  color: #333;
}

.kinobox_player {
  min-height: 500px;
  min-width: 900px;
  width: 100%;
  height: auto;
  margin-top: 30px;
  border-radius: 8px;
}

.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  margin-top: 20px;
}

.rating > input {
  display: none;
}

.rating > label {
  position: relative;
  width: 1em;
  font-size: 30px;
  font-weight: 300;
  color: #ffd600;
  cursor: pointer;
}

.rating > label::before {
  content: "\2605";
  position: absolute;
  opacity: 0;
}

.rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
}

.rating > input:checked ~ label:before {
  opacity: 1;
}

.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
}

.rating-submit {
  background-color: #4caf50;
  border-radius: 8px;
  color: #fff;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.rating-submit:hover {
  background-color: #45a049;
}

.comment-text {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: none;
}

.container-input button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
}

.container-input button:hover {
  background-color: #45a049;
}

.container-text textarea {
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
}
